import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader, CardLayout } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Image, List, Text, Item, Anchor, Button, Heading } from "../../components/elements";
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from "react-router-dom";

export default function StableSwap() {

    const contractAddress = "0x74fE5097392F2634974372bdfA115884Ffa24F54";
    const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"_cOwner","type":"address"},{"internalType":"address","name":"_tokenAddress","type":"address"},{"internalType":"address","name":"_stableSwap","type":"address"}],"name":"createSwap","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"getNewSwapContract","outputs":[{"internalType":"contract StableSwap","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newSwapContract","outputs":[{"internalType":"contract StableSwap","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
    const fee = "30000000000000000";

    let myNewContract = "";
    let tempNewContract = "";
    const navigate = useNavigate();

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [ContractText, setContractText] = useState(null);
    const [WalletText, setWalletText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Contract")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")

    const swapContract = "0x4C5868093bc838A9F34b16b8223789E4eaEE1dB3";



      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };

      const handleWalletChange = (event) => {
        setWalletText(event.target.value);
      };




      const connectWalletButton = () => {
        return (
          <Button onClick={() => open()} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }

      const createButton = () => {
        return (
          <Button onClick={createNewContract} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }


      useEffect(() => {

      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createNewContract = async() => {
        try{


          if(isConnected){
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            console.log("Intialize payment");
            let newAutoBuy = await contract.createSwap(WalletText, ContractText, swapContract, {value: fee});
            tempNewContract = await contract.getNewSwapContract();
            myNewContract = tempNewContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempNewContract === myNewContract){
              myNewContract = await contract.getNewSwapContract();
              await timeout();
            }
        if(newAutoBuy){
          navigate(`/stable-swap-contract?id=${myNewContract}&chain=bsc`);
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
          console.log(err);
        }

      }

    return (
        <PageLayout>
            <Heading as="h2" className="mc-overview-title">Stable Token DEX</Heading>
            <h2>Your Token = $1 (Swap your stable token with BNB & USDX)</h2>
            <h2>Earn swap fee</h2>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="1- Create Stable Swap Contract" />
                        <div>This contract is required to create your own stable swap website.</div>
                        <p>Supported Blockchains: BNB Smart Chain Only.</p>
                        <div>Your token decimals must be 18 and without tax functions.</div>
                        <div>Creation Fee: 0.03 BNB.</div>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleWalletChange} value={WalletText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a.............." />
                                <LabelField onChange={handleContractChange} value={ContractText} label="Stable Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a.............." />
                            </Col>
                        </Row>
                        <br />
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {isConnected ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
            <br />
            <CardLayout>
                <List className="mc-notify-list">
                        <Item className="mc-notify-item" key="token">
                            <Anchor className="mc-notify-content" href="https://bit.ly/0xStableSwap">
                                <Box className="mc-notify-media">
                                    <Image src="images/download.png" alt="download ico launchpad" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">2- Download Stable Swap Website (BNB Smart Chain)</Text>
                                    <Text as="small">Download full ready stable swap website, Create your own swap website for your stable token and let your users exchange between your token and USDT.</Text>
                                </Box>
                            </Anchor>
                            <div>
                            <Anchor href="https://bit.ly/0xStableSwap">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                            <br />
                            <Anchor href="https://stable.0xfactory.com/" target={'blank'}>
                                <Button className="mc-btn green m-2">Live Demo</Button>
                            </Anchor>
                            </div>
                        </Item>

                </List>
            </CardLayout>
        </PageLayout>
    )
}
