import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Documentation, ChangeLog, Error } from "./pages/supports";
import { Avatars, Alerts, Buttons, Charts, Tables, Fields, Headings, Colors } from "./pages/blocks";
import { Ecommerce, Analytics, CRM, ForgotPassword, UserList, UserProfile, MyAccount,
    ProductList, ProductView, ProductUpload, InvoiceList, InvoiceDetails, OrderList, Message,
    Notification, BlankPage, Settings } from "./pages/master";
import { BscToken, EthToken, PolyToken, AvaxToken, FtmToken, TestToken, QbitToken, StratisToken, CoreToken, ReiToken } from "./pages/tokens";
import { HomePage, ComingSoon, Zxf, ZxfTr, ZxfRu, ZxfGr, TokensCreator, Tut } from "./pages/home";
import { Contract, CreateLaunchpad, CreateLaunchpadPlus } from "./pages/launchpad";
import { CreateAirdrop, ContractAirdrop, AirdropAff } from "./pages/airdrop";
import { TokensContracts, UtilContracts, Apps, FreeApps, PremiumApps } from "./pages/contracts";
import { AddChain } from "./pages/tools";
import { BuyBurn, BuyBurnContract } from "./pages/buyBurn";
import { AutoBuy, AutoBuyContract } from "./pages/autoBuy";
import { EasySell, EasySellContract } from "./pages/easySell";
import { EasyBuy, EasyBuyContract } from "./pages/easyBuy";
import { UsdtConverter, UsdtConverterContract} from "./pages/usdtConcerter";
import Marketing from "./pages/marketing/marketing";
import { Eth, BscChain, Base, Arbitrum, StratisChain, PolygonChain, CoreChain, KronobitChain } from "./pages/blockchains";
import { Dex, Cex, Swap, StableSwap, StableSwapContract, Trading } from "./pages/exchanges";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

// 1. Get projectId
  const projectId = process.env.REACT_APP_PROJECT_ID;

  // 2. Set chains
  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }
  const bsc = {
    chainId: 56,
    name: 'BNB Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org'
  }
  const polygon = {
    chainId: 137,
    name: 'Polygon Mainnet',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-rpc.com'
  }
  const base = {
    chainId: 8453,
    name: 'Base Network',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://developer-access-mainnet.base.org'
  }
  const avax = {
    chainId: 43114,
    name: 'Avalanche C-Chain',
    currency: 'AVAX',
    explorerUrl: 'https://snowtrace.io',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc'
  }
  const ftm = {
    chainId: 250,
    name: 'Fantom Opera',
    currency: 'FTM',
    explorerUrl: 'https://ftmscan.com',
    rpcUrl: 'https://rpc.ftm.tools'
  }
  const core = {
    chainId: 1116,
    name: 'Core Blockchain',
    currency: 'CORE',
    explorerUrl: 'https://scan.coredao.org',
    rpcUrl: 'https://rpc.coredao.org'
  }
  const bscTestnet = {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    currency: 'tBNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
  }
  const knb = {
    chainId: 13600,
    name: 'Kronobit Networks',
    currency: 'KNB',
    explorerUrl: 'https://explorer.qbitscan.com',
    rpcUrl: 'https://mainnet-rpc.qbitscan.com'
  }
  const stratis = {
    chainId: 105105,
    name: 'Stratis Mainnet',
    currency: 'STRAX',
    explorerUrl: 'https://explorer.stratisevm.com',
    rpcUrl: 'https://rpc.stratisevm.com'
  }
  const arb = {
    chainId: 42161,
    name: 'Arbitrum One',
    currency: 'ETH',
    explorerUrl: 'https://arbiscan.io',
    rpcUrl: 'https://arb1.arbitrum.io/rpc'
  }


  // 3. Create a metadata object
  const metadata = {
    name: '0xFactory',
    description: '0xFactory Platform',
    url: 'https://0xfactory.com', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }

  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    //rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 56 // used for the Coinbase SDK
  })

  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    chains: [bsc, mainnet, polygon, base, arb, avax, ftm, bscTestnet, core, knb, stratis],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })

export default function App() {
    return (
        <ThemeProvider>
            <LoaderProvider>
                <BrowserRouter>
                    <Routes>
                        {/* Home Page */}
                        <Route path="*" element={<Error />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/zxf" element={<Zxf />} />
                        <Route path="/presale" element={<Zxf />} />
                        <Route path="/private-sale" element={<Zxf />} />
                        <Route path="/tr" element={<ZxfTr />} />
                        <Route path="/ru" element={<ZxfRu />} />
                        <Route path="/gr" element={<ZxfGr />} />
                        <Route path="/dex" element={<Dex />} />
                        <Route path="/0xswap" element={<Swap />} />
                        <Route path="/stable-swap" element={<StableSwap />} />
                        <Route path="/trading-bot" element={<Trading />} />
                        <Route path="/stable-swap-contract" element={<StableSwapContract />} />
                        <Route path="/cex-listing" element={<Cex />} />
                        <Route path="/marketing" element={<Marketing />} />
                        <Route path="/tokens-creator" element={<TokensCreator />} />
                        {/* Blockchains Pages */}
                        <Route path="/smartchain" element={<BscChain /> } />
                        <Route path="/ethereum" element={<Eth /> } />
                        <Route path="/base" element={<Base /> } />
                        <Route path="/arbitrum" element={<Arbitrum /> } />
                        <Route path="/polygon" element={<PolygonChain /> } />
                        <Route path="/stratis" element={<StratisChain /> } />
                        <Route path="/core" element={<CoreChain /> } />
                        <Route path="/kronobit" element={<KronobitChain /> } />
                        {/* Tokens Pages */}
                        <Route path="/bsc-token" element={<BscToken /> } />
                        <Route path="/eth-token" element={<EthToken /> } />
                        <Route path="/poly-token" element={<PolyToken /> } />
                        <Route path="/avax-token" element={<AvaxToken /> } />
                        <Route path="/ftm-token" element={<FtmToken /> } />
                        <Route path="/stratis-token" element={<StratisToken /> } />
                        <Route path="/rei-token" element={<ReiToken /> } />
                        <Route path="/core-token" element={<CoreToken /> } />
                        <Route path="/test-token" element={<TestToken /> } />
                        <Route path="/knb-token" element={<QbitToken /> } />
                        <Route path="/token-affiliate" element={<EasySell /> } />
                        <Route path="/token-affiliate-contract" element={<EasySellContract /> } />
                        {/* Launchpad Pages */}
                        <Route path="/launchpad-contract" element={<CreateLaunchpad /> } />
                        <Route path="/launchpad-contract-plus" element={<CreateLaunchpadPlus /> } />
                        <Route path="/contract-launchpad" element={<Contract /> } />
                        {/* Airdrop Pages */}
                        <Route path="/airdrop-contract" element={<CreateAirdrop /> } />
                        <Route path="/contract-airdrop" element={<ContractAirdrop /> } />
                        <Route path="/airdrop-affiliate" element={<AirdropAff /> } />
                        {/* Solo Pages */}
                        <Route path="/usdt-converter" element={<UsdtConverter /> } />
                        <Route path="/usdt-converter-contract" element={<UsdtConverterContract /> } />
                        <Route path="/buy-burn" element={<BuyBurn /> } />
                        <Route path="/buy-burn-contract" element={<BuyBurnContract /> } />
                        <Route path="/easy-buy" element={<EasyBuy /> } />
                        <Route path="/easy-buy-contract" element={<EasyBuyContract /> } />
                        <Route path="/auto-buy" element={<AutoBuy /> } />
                        <Route path="/auto-buy-contract" element={<AutoBuyContract /> } />

                        {/* Download Contracts Pages */}
                        <Route path="/tokens-contracts" element={<TokensContracts /> } />
                        <Route path="/dapps" element={<Apps /> } />
                        <Route path="/free-dapps" element={<FreeApps /> } />
                        <Route path="/premium-dapps" element={<PremiumApps /> } />
                        <Route path="/contracts" element={<UtilContracts /> } />
                        <Route path="/nft" element={<ComingSoon /> } />

                        {/* Tools */}
                        <Route path="/video-tutorials" element={<Tut /> } />
                        <Route path="/add-chain" element={<AddChain /> } />
                        <Route path="/airdrop-ui" element={<ComingSoon /> } />


                        {/* master Pages */}
                        <Route path="/ecommerce" element={<Ecommerce /> } />
                        <Route path="/analytics" element={<Analytics /> } />
                        <Route path="/crm" element={<CRM /> } />
                        <Route path="/login" element={<HomePage />} />
                        <Route path="/register" element={<HomePage />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route path="/user-profile" element={<UserProfile />} />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route path="/product-list" element={<ProductList />} />
                        <Route path="/product-view" element={<ProductView />} />
                        <Route path="/product-upload" element={<ProductUpload />} />
                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/invoice-details" element={<InvoiceDetails />} />
                        <Route path="/order-list" element={<OrderList />} />
                        <Route path="/message" element={<Message />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/blank-page" element={<BlankPage />} />

                        {/* Blocks Pages */}
                        <Route path="/headings" element={<Headings />} />
                        <Route path="/buttons" element={<Buttons />} />
                        <Route path="/avatars" element={<Avatars />} />
                        <Route path="/colors" element={<Colors />} />
                        <Route path="/charts" element={<Charts />} />
                        <Route path="/tables" element={<Tables />} />
                        <Route path="/fields" element={<Fields />} />
                        <Route path="/alerts" element={<Alerts />} />

                        {/* Supports Pages */}
                        <Route path="/documentation" element={<Documentation />} />
                        <Route path="/changelog" element={<ChangeLog />} />
                    </Routes>
                </BrowserRouter>
            </LoaderProvider>
        </ThemeProvider>
    );
}
